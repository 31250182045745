import React from "react";
import "./ClubInfoStatus.css";
import { useTranslation } from "react-i18next";

const ClubInfo = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="club-info-status-container">
      <div className="club-info-status-header">
        <h2>{t("TheClubStatus.TheClubStatus")}</h2>
      </div>
      <div className="club-info-status-body">
        <p>
          <strong>
            {t("TheClubStatus.TheClubStatus1")}
            <br />
            <br />
            {t("TheClubStatus.TheClubStatus2")}
            <br />
            {t("TheClubStatus.TheClubStatus3")}
          </strong>
        </p>
        <br />
        <p>
          <strong>{t("TheClubStatus.TheClubStatus4")}</strong>
          <br />
          {t("TheClubStatus.TheClubStatus5")}
        </p>
        <p>
          <strong>{t("TheClubStatus.TheClubStatus6")}</strong>
          <br />
          {t("TheClubStatus.TheClubStatus7")}
        </p>
        <p>
          <strong>{t("TheClubStatus.TheClubStatus8")}</strong>
          <br />
          {t("TheClubStatus.TheClubStatus9")}
        </p>
        <p>
          <strong>{t("TheClubStatus.TheClubStatus10")}</strong>
          <br />
          {t("TheClubStatus.TheClubStatus11")}
        </p>
        <p>
          <strong>{t("TheClubStatus.TheClubStatus12")}</strong>
          <br />
          {t("TheClubStatus.TheClubStatus13")}
        </p>
        <br />
        <p>
          <strong>
            {t("TheClubStatus.TheClubStatus14")}
            <br />
            {t("TheClubStatus.TheClubStatus15")}
          </strong>
        </p>
        <br />
        <p>
          <strong>{t("TheClubStatus.TheClubStatus16")}</strong>
          <br />
          {t("TheClubStatus.TheClubStatus17")}
        </p>
        <p>
          <strong>{t("TheClubStatus.TheClubStatus18")}</strong>
          <br />
          {t("TheClubStatus.TheClubStatus18")}
        </p>
        <p>
          <strong>{t("TheClubStatus.TheClubStatus20")}</strong>
          <br />
          {t("TheClubStatus.TheClubStatus21")}
        </p>
        <br />
        <p>
          <strong>
            {t("TheClubStatus.TheClubStatus22")}
            <br />
            {t("TheClubStatus.TheClubStatus23")}
            <br />
            {t("TheClubStatus.TheClubStatus24")}
          </strong>
        </p>
        <br />
        <p>
          <strong>{t("TheClubStatus.TheClubStatus25")}</strong>
          <br />
          {t("TheClubStatus.TheClubStatus26")}
        </p>
        <p>
          <strong>{t("TheClubStatus.TheClubStatus27")}</strong>
          <br />
          {t("TheClubStatus.TheClubStatus28")}
          <br />
          {t("TheClubStatus.TheClubStatus29")}
          <br />
          {t("TheClubStatus.TheClubStatus30")}
          <br />
          {t("TheClubStatus.TheClubStatus31")}
          <br />
          {t("TheClubStatus.TheClubStatus32")}
          <br />
          {t("TheClubStatus.TheClubStatus33")}
          <br />
          {t("TheClubStatus.TheClubStatus34")}
          <br />
          {t("TheClubStatus.TheClubStatus35")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus36")}</strong>
          <br />
          {t("TheClubStatus.TheClubStatus37")}
          <br />
          {t("TheClubStatus.TheClubStatus38")}
          <br />
          {t("TheClubStatus.TheClubStatus39")}
          <br />
          {t("TheClubStatus.TheClubStatus40")}
          <br />
          {t("TheClubStatus.TheClubStatus41")}
          <br />
          {t("TheClubStatus.TheClubStatus42")}
          <br />
          {t("TheClubStatus.TheClubStatus43")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus44")}</strong>
          <br />
          {t("TheClubStatus.TheClubStatus45")}
          <br />
          {t("TheClubStatus.TheClubStatus46")}
          <br />
          {t("TheClubStatus.TheClubStatus47")}
          <br />
          {t("TheClubStatus.TheClubStatus48")}
          <br />
          {t("TheClubStatus.TheClubStatus49")}
          <br />
          {t("TheClubStatus.TheClubStatus50")}
          <br />
        </p>
        <br />
        <p>
          <strong>
            {t("TheClubStatus.TheClubStatus51")}
            <br />
            {t("TheClubStatus.TheClubStatus52")}
          </strong>
        </p>
        <br />
        <p>
          <strong>{t("TheClubStatus.TheClubStatus53")}</strong>
          <br />
          {t("TheClubStatus.TheClubStatus54")}
          <br />
          {t("TheClubStatus.TheClubStatus55")}
          <br />
          {t("TheClubStatus.TheClubStatus56")}
          <br />
          {t("TheClubStatus.TheClubStatus57")}
          <br />
          {t("TheClubStatus.TheClubStatus58")}
          <br />
          {t("TheClubStatus.TheClubStatus59")}
          <br />
          {t("TheClubStatus.TheClubStatus60")}
          <br />
          {t("TheClubStatus.TheClubStatus61")}
          <br />
          {t("TheClubStatus.TheClubStatus62")}
          <br />
          {t("TheClubStatus.TheClubStatus63")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus64")}</strong>

          <br />
          {t("TheClubStatus.TheClubStatus65")}
          <br />
          {t("TheClubStatus.TheClubStatus66")}
          <br />
          {t("TheClubStatus.TheClubStatus67")}
          <br />
          {t("TheClubStatus.TheClubStatus68")}
          <br />
          {t("TheClubStatus.TheClubStatus69")}
          <br />
          {t("TheClubStatus.TheClubStatus70")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus71")}</strong>
          <br />
          {t("TheClubStatus.TheClubStatus72")}
          <br />
          {t("TheClubStatus.TheClubStatus73")}
        </p>
        <br />
        <p>
          <strong>
            {t("TheClubStatus.TheClubStatus74")}
            <br />
            {t("TheClubStatus.TheClubStatus75")}
          </strong>
        </p>
        <p>
          <strong>{t("TheClubStatus.TheClubStatus76")}</strong>
          <br />
          {t("TheClubStatus.TheClubStatus77")}
          <br />
          {t("TheClubStatus.TheClubStatus78")}
          <br />
          {t("TheClubStatus.TheClubStatus79")}
          <br />
          {t("TheClubStatus.TheClubStatus80")}
        </p>
        <br />
        <p>
          <strong>
            {t("TheClubStatus.TheClubStatus81")}
            <br />
            {t("TheClubStatus.TheClubStatus82")}
          </strong>
        </p>
        <br />
        <p>
          <strong>{t("TheClubStatus.TheClubStatus83")}</strong> <br />
          {t("TheClubStatus.TheClubStatus84")}
          <br />
          {t("TheClubStatus.TheClubStatus85")}
          <br />
          {t("TheClubStatus.TheClubStatus86")}
          <br />
          {t("TheClubStatus.TheClubStatus87")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus88")}</strong> <br />
          {t("TheClubStatus.TheClubStatus89")}
          <br />
          {t("TheClubStatus.TheClubStatus90")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus91")}</strong> <br />
          {t("TheClubStatus.TheClubStatus92")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus93")}</strong> <br />
          {t("TheClubStatus.TheClubStatus94")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus95")}</strong> <br />
          {t("TheClubStatus.TheClubStatus96")}
          <strong>{t("TheClubStatus.TheClubStatus97")}</strong> <br />
          {t("TheClubStatus.TheClubStatus98")} <br />
        </p>
        <br />
        <p>
          <strong>
            {t("TheClubStatus.TheClubStatus99")}
            <br />
            {t("TheClubStatus.TheClubStatus100")}
          </strong>
        </p>
        <br />
        <p>
          <strong>{t("TheClubStatus.TheClubStatus101")}</strong> <br />
          {t("TheClubStatus.TheClubStatus102")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus103")}</strong> <br />
          {t("TheClubStatus.TheClubStatus104")}
          <br />
          {t("TheClubStatus.TheClubStatus105")}
          <br />
          {t("TheClubStatus.TheClubStatus106")}
          <br />
          {t("TheClubStatus.TheClubStatus107")}
          <br />
          {t("TheClubStatus.TheClubStatus108")}
          <br />
          {t("TheClubStatus.TheClubStatus109")}
          <br />
          {t("TheClubStatus.TheClubStatus110")}
          <br />
          {t("TheClubStatus.TheClubStatus111")}
          <br />
          {t("TheClubStatus.TheClubStatus112")}
          <br />
          {t("TheClubStatus.TheClubStatus113")}
          <br />
          {t("TheClubStatus.TheClubStatus114")}
          <br />
          {t("TheClubStatus.TheClubStatus115")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus116")}</strong> <br />
          {t("TheClubStatus.TheClubStatus117")}
          <br />
          {t("TheClubStatus.TheClubStatus118")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus119")}</strong> <br />
          {t("TheClubStatus.TheClubStatus120")}
          <br />
          {t("TheClubStatus.TheClubStatus121")}
          <br />
          {t("TheClubStatus.TheClubStatus122")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus123")}</strong> <br />
          {t("TheClubStatus.TheClubStatus124")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus125")}</strong> <br />
          {t("TheClubStatus.TheClubStatus126")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus127")}</strong> <br />
          {t("TheClubStatus.TheClubStatus128")}
          <br />
          {t("TheClubStatus.TheClubStatus129")}
          <br />
          {t("TheClubStatus.TheClubStatus130")}
          <br />
          {t("TheClubStatus.TheClubStatus131")}
          <br />
          {t("TheClubStatus.TheClubStatus132")}
          <br />
          {t("TheClubStatus.TheClubStatus133")}
          <br />
          {t("TheClubStatus.TheClubStatus134")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus135")}</strong> <br />
          {t("TheClubStatus.TheClubStatus136")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus137")}</strong> <br />
          {t("TheClubStatus.TheClubStatus138")}
          <br />
          {t("TheClubStatus.TheClubStatus139")}
          <br />
          {t("TheClubStatus.TheClubStatus140")}
          <br />
          {t("TheClubStatus.TheClubStatus141")}
          <br />
          {t("TheClubStatus.TheClubStatus142")}
          <br />
          {t("TheClubStatus.TheClubStatus143")}
          <br />
          {t("TheClubStatus.TheClubStatus144")}
          <br />
          {t("TheClubStatus.TheClubStatus145")}
          <br />
          {t("TheClubStatus.TheClubStatus146")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus147")}</strong> <br />
          {t("TheClubStatus.TheClubStatus148")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus149")}</strong> <br />
          {t("TheClubStatus.TheClubStatus150")}
          <br />
          {t("TheClubStatus.TheClubStatus151")}
          <br />
          {t("TheClubStatus.TheClubStatus152")}
          <br />
          {t("TheClubStatus.TheClubStatus153")}
        </p>
        <br />
        <p>
          <strong>
            {t("TheClubStatus.TheClubStatus154")}
            <br />
            {t("TheClubStatus.TheClubStatus155")}
          </strong>
        </p>
        <br />
        <p>
          <strong>{t("TheClubStatus.TheClubStatus156")}</strong> <br />
          {t("TheClubStatus.TheClubStatus157")}
          <br />
          {t("TheClubStatus.TheClubStatus158")}
          <br />
          {t("TheClubStatus.TheClubStatus159")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus160")}</strong> <br />
          {t("TheClubStatus.TheClubStatus161")}
          <br />
          {t("TheClubStatus.TheClubStatus162")}
          <br />
          {t("TheClubStatus.TheClubStatus163")}
          <br />
          {t("TheClubStatus.TheClubStatus164")}
          <br />
          {t("TheClubStatus.TheClubStatus165")}
          <br />
          {t("TheClubStatus.TheClubStatus166")}
          <br />
          {t("TheClubStatus.TheClubStatus167")}
          <br />
          {t("TheClubStatus.TheClubStatus168")}
          <br />
          {t("TheClubStatus.TheClubStatus169")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus170")}</strong> <br />
          {t("TheClubStatus.TheClubStatus171")}
          <br />
          {t("TheClubStatus.TheClubStatus172")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus173")}</strong> <br />
          {t("TheClubStatus.TheClubStatus174")}
          <br />
          {t("TheClubStatus.TheClubStatus175")}
          <br />
          {t("TheClubStatus.TheClubStatus176")}
          <br />
          {t("TheClubStatus.TheClubStatus177")}
          <br />
          {t("TheClubStatus.TheClubStatus178")}
          <br />
          {t("TheClubStatus.TheClubStatus179")}
          <br />
          {t("TheClubStatus.TheClubStatus180")}
          <br />
          {t("TheClubStatus.TheClubStatus181")}
          <br />
          {t("TheClubStatus.TheClubStatus182")}
          <br />
          {t("TheClubStatus.TheClubStatus183")}
          <br />
          {t("TheClubStatus.TheClubStatus184")}
          <br />
          {t("TheClubStatus.TheClubStatus185")}
          <br />
          {t("TheClubStatus.TheClubStatus186")}
          <br />
          {t("TheClubStatus.TheClubStatus187")}
          <br />
          {t("TheClubStatus.TheClubStatus188")}
          <br />
          {t("TheClubStatus.TheClubStatus189")}
          <br />
          {t("TheClubStatus.TheClubStatus190")}
          <br />
          {t("TheClubStatus.TheClubStatus191")}
          <br />
          {t("TheClubStatus.TheClubStatus192")}
          <br />
          {t("TheClubStatus.TheClubStatus193")}
          <br />
          {t("TheClubStatus.TheClubStatus194")}
          <br />
          {t("TheClubStatus.TheClubStatus195")}
          <br />
          {t("TheClubStatus.TheClubStatus196")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus197")}</strong> <br />
          {t("TheClubStatus.TheClubStatus198")}
          <br />
          {t("TheClubStatus.TheClubStatus199")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus200")}</strong> <br />
          {t("TheClubStatus.TheClubStatus201")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus202")}</strong> <br />
          {t("TheClubStatus.TheClubStatus203")}
          <br />
          {t("TheClubStatus.TheClubStatus204")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus205")}</strong> <br />
          {t("TheClubStatus.TheClubStatus206")}
          <br />
          {t("TheClubStatus.TheClubStatus207")}
          <br />
        </p>
        <br />
        <p>
          <strong>
            {t("TheClubStatus.TheClubStatus208")}
            <br />
            {t("TheClubStatus.TheClubStatus209")}
          </strong>
        </p>
        <br />
        <p>
          <strong>{t("TheClubStatus.TheClubStatus210")}</strong> <br />
          {t("TheClubStatus.TheClubStatus211")}
          <br />
          {t("TheClubStatus.TheClubStatus212")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus213")}</strong> <br />
          {t("TheClubStatus.TheClubStatus214")}
          <br />
          {t("TheClubStatus.TheClubStatus215")}
          <br />
          {t("TheClubStatus.TheClubStatus216")}
          <br />
          {t("TheClubStatus.TheClubStatus217")}
          <br />
          {t("TheClubStatus.TheClubStatus218")}
          <br />
          {t("TheClubStatus.TheClubStatus219")}
          <br />
          {t("TheClubStatus.TheClubStatus220")}
          <br />
        </p>
        <br />
        <p>
          <strong>
            {t("TheClubStatus.TheClubStatus221")}
            <br />
            {t("TheClubStatus.TheClubStatus222")}
          </strong>
        </p>
        <br />
        <p>
          <strong>{t("TheClubStatus.TheClubStatus223")}</strong> <br />
          {t("TheClubStatus.TheClubStatus224")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus225")}</strong> <br />
          {t("TheClubStatus.TheClubStatus226")}
          <br />
          {t("TheClubStatus.TheClubStatus227")}
          <br />
          {t("TheClubStatus.TheClubStatus228")}
          <br />
          {t("TheClubStatus.TheClubStatus229")}
          <br />
          {t("TheClubStatus.TheClubStatus230")}
          <br />
          {t("TheClubStatus.TheClubStatus231")}
          <br />
          {t("TheClubStatus.TheClubStatus232")}
          <br />
          {t("TheClubStatus.TheClubStatus233")}
          <br />
          {t("TheClubStatus.TheClubStatus234")}
          <br />
          {t("TheClubStatus.TheClubStatus235")}
          <br />
          {t("TheClubStatus.TheClubStatus236")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus237")}</strong> <br />
          {t("TheClubStatus.TheClubStatus238")}
          <br />
          {t("TheClubStatus.TheClubStatus239")}
          <br />
          {t("TheClubStatus.TheClubStatus240")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus241")}</strong> <br />
          {t("TheClubStatus.TheClubStatus242")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus243")}</strong> <br />
          {t("TheClubStatus.TheClubStatus244")}
          <br />
          {t("TheClubStatus.TheClubStatus245")}
          <strong>{t("TheClubStatus.TheClubStatus246")}</strong> <br />
          {t("TheClubStatus.TheClubStatus247")}
          <br />
          {t("TheClubStatus.TheClubStatus248")}
        </p>
        <br />
        <p>
          <strong>
            {t("TheClubStatus.TheClubStatus249")}
            <br />
            {t("TheClubStatus.TheClubStatus250")}
          </strong>
        </p>
        <br />
        <p>
          <strong>{t("TheClubStatus.TheClubStatus251")}</strong>
          <br />
          {t("TheClubStatus.TheClubStatus252")}
          <br />
        </p>
        <br />
        <br />
        <p>
          <strong>
            {t("TheClubStatus.TheClubStatus253")}
            <br />
            {t("TheClubStatus.TheClubStatus254")}
          </strong>
        </p>
        <br />
        <p>
          <strong>{t("TheClubStatus.TheClubStatus255")}</strong>
          <br />
          {t("TheClubStatus.TheClubStatus256")}
          <br />
          {t("TheClubStatus.TheClubStatus257")}
          <br />
          {t("TheClubStatus.TheClubStatus258")}
          <br />
          {t("TheClubStatus.TheClubStatus259")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus260")}</strong>
          <br />
          {t("TheClubStatus.TheClubStatus261")}
          <br />
          {t("TheClubStatus.TheClubStatus262")}
          <br />
          {t("TheClubStatus.TheClubStatus263")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus264")}</strong>
          <br />
          {t("TheClubStatus.TheClubStatus265")}
          <br />
          {t("TheClubStatus.TheClubStatus266")}
          <br />
          {t("TheClubStatus.TheClubStatus267")}
          <br />
          {t("TheClubStatus.TheClubStatus268")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus269")}</strong>
          <br />
          {t("TheClubStatus.TheClubStatus270")}
          <br />
          {t("TheClubStatus.TheClubStatus271")}
          <br />
          {t("TheClubStatus.TheClubStatus272")}
          <br />
          {t("TheClubStatus.TheClubStatus273")}
          <br />
        </p>
        <br />
        <p>
          <strong>
            {t("TheClubStatus.TheClubStatus274")}
            <br />
            {t("TheClubStatus.TheClubStatus275")}
          </strong>
        </p>
        <br />
        <p>
          <br />
          <strong>{t("TheClubStatus.TheClubStatus276")}</strong> <br />
          {t("TheClubStatus.TheClubStatus277")}
          <br />
          {t("TheClubStatus.TheClubStatus278")}
          <br />
          {t("TheClubStatus.TheClubStatus279")}
          <br />
          {t("TheClubStatus.TheClubStatus280")}
          <br />
          {t("TheClubStatus.TheClubStatus281")}
          <br />
        </p>
        <br />
        <p>
          <strong>
            {t("TheClubStatus.TheClubStatus282")}
            <br />
            {t("TheClubStatus.TheClubStatus283")}
          </strong>
        </p>
        <p>
          <br />
          <strong>{t("TheClubStatus.TheClubStatus284")}</strong> <br />
          {t("TheClubStatus.TheClubStatus285")}
          <br />
          {t("TheClubStatus.TheClubStatus286")}
          <br />
          {t("TheClubStatus.TheClubStatus287")}
          <br />
          {t("TheClubStatus.TheClubStatus288")}
          <br />
          {t("TheClubStatus.TheClubStatus289")}
          <br />
          {t("TheClubStatus.TheClubStatus290")}
          <br />
          {t("TheClubStatus.TheClubStatus291")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus292")}</strong> <br />
          {t("TheClubStatus.TheClubStatus293")}
          <br />
          {t("TheClubStatus.TheClubStatus294")}
          <br />
          {t("TheClubStatus.TheClubStatus295")}
          <br />
          {t("TheClubStatus.TheClubStatus296")}
          <br />
          {t("TheClubStatus.TheClubStatus297")}
          <br />
          {t("TheClubStatus.TheClubStatus298")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus299")}</strong> <br />
          {t("TheClubStatus.TheClubStatus300")}
          <br />
          {t("TheClubStatus.TheClubStatus301")}
          <br />
          {t("TheClubStatus.TheClubStatus302")}
          <br />
          {t("TheClubStatus.TheClubStatus303")}
          <br />
          {t("TheClubStatus.TheClubStatus304")}
          <br />
        </p>
        <br />
        <p>
          <strong>
            {t("TheClubStatus.TheClubStatus305")}
            <br />
            {t("TheClubStatus.TheClubStatus306")}
          </strong>
        </p>
        <br />
        <p>
          <br />
          <strong>{t("TheClubStatus.TheClubStatus307")}</strong> <br />
          {t("TheClubStatus.TheClubStatus308")}
          <br />
          {t("TheClubStatus.TheClubStatus309")}
          <br />
          {t("TheClubStatus.TheClubStatus310")}
          <br />
          {t("TheClubStatus.TheClubStatus311")}
          <br />
          <br />
          <strong>{t("TheClubStatus.TheClubStatus312")}</strong> <br />
          {t("TheClubStatus.TheClubStatus313")}
          <br />
        </p>
      </div>
      <br /> <br /> <br /> <br />
    </div>
  );
};

export default ClubInfo;
