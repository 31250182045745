import React, { useState } from "react";
import "./CertificationsDescription.css";
import { useTranslation } from "react-i18next";
import carIcon from "../../../Images/car.png";
import motorcycleIcon from "../../../Images/motorcycle.png";
import { Link } from "react-router-dom";
import MembershipForm from "../CertificationsBooking/MembershipFormCertefications";

const PriceCard = ({ title, prices, iconSrc }) => (
  <div className="price-card">
    <h3 className="price-card-title">{title}</h3>
    {prices.map((item, index) => (
      <div key={index} className="price-item">
        <img
          src={iconSrc[index % iconSrc.length]}
          alt="icon"
          className="price-icon"
        />
        <span>
          {item.description}{" "}
          <span className="price-highlight">{item.price}</span>
        </span>
      </div>
    ))}
  </div>
);

const FivaDescription = () => {
  const { t, i18n } = useTranslation();
  const [showMembershipForm, setShowMembershipForm] = useState(false);

  const categories = [
    {
      id: "A",
      years: t("CerteficationsInfo.CerteficationsInfo11"),
      shelfLife: t("CerteficationsInfo.CerteficationsInfo12"),
    },
    {
      id: "B",
      years: t("CerteficationsInfo.CerteficationsInfo14"),
      shelfLife: t("CerteficationsInfo.CerteficationsInfo15"),
    },
    {
      id: "C",
      years: t("CerteficationsInfo.CerteficationsInfo17"),
      shelfLife: t("CerteficationsInfo.CerteficationsInfo18"),
    },
    {
      id: "D",
      years: t("CerteficationsInfo.CerteficationsInfo20"),
      shelfLife: t("CerteficationsInfo.CerteficationsInfo21"),
    },
    {
      id: "E",
      years: t("CerteficationsInfo.CerteficationsInfo23"),
      shelfLife: t("CerteficationsInfo.CerteficationsInfo24"),
    },
    {
      id: "F",
      years: t("CerteficationsInfo.CerteficationsInfo26"),
      shelfLife: t("CerteficationsInfo.CerteficationsInfo27"),
    },
    {
      id: "G",
      years: t("CerteficationsInfo.CerteficationsInfo29"),
      shelfLife: t("CerteficationsInfo.CerteficationsInfo30"),
    },
    {
      id: "H",
      years: t("CerteficationsInfo.CerteficationsInfo32"),
      shelfLife: t("CerteficationsInfo.CerteficationsInfo33"),
    },
  ];

  const memberPrices = [
    {
      description: t("CerteficationsInfo.CerteficationsInfo4"),
      price: t("CerteficationsInfo.CerteficationsInfo4.1"),
    },
    {
      description: t("CerteficationsInfo.CerteficationsInfo5"),
      price: t("CerteficationsInfo.CerteficationsInfo5.1"),
    },
  ];

  const nonMemberPrices = [
    {
      description: t("CerteficationsInfo.CerteficationsInfo7"),
      price: t("CerteficationsInfo.CerteficationsInfo7.1"),
    },
    {
      description: t("CerteficationsInfo.CerteficationsInfo8"),
      price: t("CerteficationsInfo.CerteficationsInfo8.1"),
    },
  ];

  return (
    <div className="fiva-description-container">
      <br></br>

      <div className="fiva-description-container">
        <div className="prices-container">
          <PriceCard
            title={t("CerteficationsInfo.CerteficationsInfo3")}
            prices={memberPrices}
            iconSrc={[carIcon, motorcycleIcon]}
          />
          <PriceCard
            title={t("CerteficationsInfo.CerteficationsInfo6")}
            prices={nonMemberPrices}
            iconSrc={[carIcon, motorcycleIcon]}
          />
        </div>
      </div>
      <br></br>

      <h2 className="fiva-description-subtitle">
        {t("CerteficationsInfo.CerteficationsInfo9")}
      </h2>
      <br />
      <div className="categories-table-container">
        <table className="categories-table">
          <thead>
            <tr>
              <th>{t("CerteficationsInfo.CerteficationsInfo43")}</th>
              <th>{t("CerteficationsInfo.CerteficationsInfo44")}</th>
            </tr>
          </thead>
          <tbody>
            {categories.map((category) => (
              <tr key={category.id}>
                <td>{`Category ${category.id} (${category.years})`}</td>
                <td>{`${category.shelfLife}`}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <br />

      <p>{t("CerteficationsInfo.CerteficationsInfo35")}</p>
      <br></br>

      <p>
        <strong>{t("CerteficationsInfo.CerteficationsInfo36")}:</strong>{" "}
        {t("CerteficationsInfo.CerteficationsInfo37")}
      </p>
      <br></br>

      <p>
        <strong>{t("CerteficationsInfo.CerteficationsInfo38")}</strong>{" "}
        {t("CerteficationsInfo.CerteficationsInfo39")}
      </p>
      <br></br>

      <p>
        <strong>{t("CerteficationsInfo.CerteficationsInfo40")}</strong>{" "}
        {t("CerteficationsInfo.CerteficationsInfo41")}
      </p>
      <br></br>
      <Link to="/CertificationsBooking">

      <button
        className="download-button"
      >
        {t("CerteficationsInfo.CerteficationsInfo42")}
      </button>
      </Link>

      {showMembershipForm && (
        <MembershipForm onClose={() => setShowMembershipForm(false)} />
      )}
    </div>
  );
};

export default FivaDescription;
