import React, { useState, useEffect } from "react";
import { firestore } from "../../../firebase-config";
import { collection, getDocs } from "firebase/firestore";
import "./MembershipInfo.css";
import { useTranslation } from "react-i18next";

const MembershipInfo = () => {
  const { t, i18n } = useTranslation();
  const [certifications, setCertifications] = useState([]);

  useEffect(() => {
    const fetchCertifications = async () => {
      const certificationsCollectionRef = collection(firestore, "certifications");
      const certificationsSnapshot = await getDocs(certificationsCollectionRef);
      const certificationsList = certificationsSnapshot.docs.map(doc => doc.data());
      setCertifications(certificationsList);
    };

    fetchCertifications();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: 'numeric', month: 'long' };
    return date.toLocaleDateString(i18n.language, options);
  };

  return (
    <div className="membership-infoCalendar">
      <h2 className="membership-titleCalendar">{t("Calendar.Calendar")}</h2>
      <p>{t("Calendar.Calendar2")}</p>
      {certifications.map((cert, index) => (
        <div key={index}>
          <h3 className="membership-subtitle">{cert.location}</h3>
          <ul className="membership-list">
            {cert.dates.map((date, i) => (
              <li key={i}>{formatDate(date)}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default MembershipInfo;
