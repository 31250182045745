import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from '../Components/HomePage';
import TheClub from "../Components/TheClub";
import TheClubVantages from "../Components/TheClubVantages";
import TheClubRegistation from "../Components/TheClubRegistation";
import TheClubReport from "../Components/TheClubReport";
import TheClubPolicy from "../Components/TheClubPolicy";
import FivaOrganization from "../Components/FivaOrganization";
import FivaLetter from "../Components/FivaLetter";
import FivaGuide from "../Components/FivaGuide";
import FivaPassport from "../Components/FivaPassport";
import CertificationsBooking from "../Components/CertificationsBooking";
import CertificationsCalendar from "../Components/CertificationsCalendar";
import CertificationsConditions from "../Components/CertificationsConditions";
import CertificationsCouncil from "../Components/CertificationsCouncil";
import CertificationsInfo from "../Components/CertificationsInfo";
import MembershipInformation from '../Components/MatriculationInformation';
import InsuranceClassic from '../Components/InsuranceClassic';
import InsurancePreClassic from '../Components/InsurancePreClassic';
import EventsCalender from '../Components/EventsCalender';
import Articles from '../Components/Articles';
import PartnersPage from "../Components/PartnersPage"
import Vehicles from "../Components/Vehicles"
import VehicleInfo from "../Components/VehiclesInfo"
import Merchendising from "../Components/Merchendising"
import MerchendisingInfo from "../Components/MerchendisingInfo"
import EventsCalendarExpired from "../Components/EventsCalenderExpired"
import PageInConstruction from '../Components/PageInConstruction';
import ScrollToTop from '../Components/ScrollToTop ';
import ArticleDetailPage from '../Components/ArticleDetailPage';
import GalleryPage from '../Components/Events/TicketsExpired/GalleryPage';
import InternationalEventsApp from '../Components/InternationalEventsApp'; // Import the InternationalEventsApp component

const AppRoutes = () => {
    return (
        <Router basename="/">
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/CLUBE" element={<TheClub />} />
                <Route path="/CLUBEVANTAGES" element={<TheClubVantages />} />
                <Route path="/CLUBEREGISTATION" element={<TheClubRegistation />} />
                <Route path="/CLUBEREPORT" element={<TheClubReport />} />
                <Route path="/CLUBEPOLICY" element={<TheClubPolicy />} />
                <Route path="/FIVAORGANIZATION" element={<FivaOrganization />} />
                <Route path="/FIVALETTER" element={<FivaLetter />} />
                <Route path="/FIVAGUIDE" element={<FivaGuide />} />
                <Route path="/FIVAPASSPORT" element={<FivaPassport />} />
                <Route path="/CertificationsBooking" element={<CertificationsBooking />} />
                <Route path="/CertificationsCalendar" element={<CertificationsCalendar />} />
                <Route path="/CertificationsConditions" element={<CertificationsConditions />} />
                <Route path="/CertificationsCouncil" element={<CertificationsCouncil />} />
                <Route path="/CertificationsInfo" element={<CertificationsInfo />} />
                <Route path="/MembershipInformation" element={<MembershipInformation />} />
                <Route path="/InsuranceClassic" element={<InsuranceClassic />} />
                <Route path="/InsurancePreClassic" element={<InsurancePreClassic />} />
                <Route path="/EventsCalender" element={<EventsCalender/>} />
                <Route path="/EventsCalendarExpired" element={<EventsCalendarExpired/>} />
                <Route path="/Articles" element={<Articles/>} />
                <Route path="/article/:slug" element={<ArticleDetailPage />} />
                <Route path="/gallery/:titlePt" element={<GalleryPage />} />
                <Route path="/Partners" element={<PartnersPage/>} />
                <Route path="/Vehicles" element={<Vehicles/>} />
                <Route path="/Vehicle/:vehicleId" element={<VehicleInfo />} />
                <Route path="/Merchendising" element={<Merchendising/>} />
                <Route path="/Merchendising/:productId" element={<MerchendisingInfo />} />
                <Route path="/PageInConstruction" element={<PageInConstruction/>} />
                <Route path="/InternationalEvents" element={<InternationalEventsApp />} /> {/* Add the route for InternationalEventsApp */}
            </Routes>
        </Router>
    );
}

export default AppRoutes;
